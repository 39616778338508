import Widgets from './Widgets';

export default class WPD_Component_Slider extends Widgets {

	items = null;
	maskClass = '.wd-slider-mask';
	prevClass = '.wd-slider-left-arrow';
	nextClass = '.wd-slider-right-arrow';
	itemClass = '.wd-slider-item';
	navClass = '.wd-slider-nav';
	dotClass = '.wd-slider-dot';
	carousel = null;

	constructor( el, options ) {
		super(el, options);
		
		const { activeIndex, startPosition, ...props } = this.options;
        props.startPosition = activeIndex;
        jQuery( this.el ).find( this.maskClass ).addClass('owl-carousel').owlCarousel( {...props, items: 1 } );
        if ( props.nav ) {
        	jQuery( this.el ).find( this.prevClass ).on('click', ::this.onPrev);
        	jQuery( this.el ).find( this.nextClass ).on('click', ::this.onNext);
        }
        if ( props.dots ) {
        	jQuery( this.el ).find( this.navClass ).find( this.dotClass ).on('click', ::this.onClickNav);
        }
        this.carousel = jQuery( this.el ).find( this.maskClass ).data('owl.carousel');
	}

	onPrev = () => {
		this.carousel && this.carousel.prev();
	}

	onNext = () => {
		this.carousel && this.carousel.next();
	}

	onClickNav = () => {
		
	}

}