import WPD_Component_Slider from './widgets/WPD_Component_Slider';
import WPD_Component_Tabs from './widgets/WPD_Component_Tabs';
import WPD_Component_Map from './widgets/WPD_Component_Map';
import WPD_Component_Counter from './widgets/WPD_Component_Counter'; 

const Components = {
	WPD_Component_Slider: WPD_Component_Slider,
    WPD_Component_Counter: WPD_Component_Counter,
	WPD_Component_Map: WPD_Component_Map,
    WPD_Component_Tabs: WPD_Component_Tabs,
};

(function( $ ) {

    for ( let i in Components ) {
        $.fn[ i ] = function( Command, options ) {

            return this.each(function() {
                
                let Component = Components[ i ];

                if ( typeof Command == 'object' ) {
                    Component = new Component( this, Command );
                    $( this ).data( i, Component );
                }

                if ( typeof Command == 'string' ) {
                    Component =  $( this ).data( i );
                    Component[ Command ] && Component[ Command ]( options );
                }

            })
        }
    }

    $(document).ready(() => {

    	$('[data-wpd-widget]').each(function(){
    		const options = $( this ).data('wpd-widget');
    		const Component = options && options.componentName && Components[options.componentName];
            
            if(jQuery.fn[options.componentName]) {
                $( this )[ options.componentName ]( options || {} );
            }
            
    	});
    	
    });

})(jQuery || $)