import Widgets from './Widgets';

export default class WPD_Component_Tabs extends Widgets {

	links 			= null;
	panes 			= null;
	tabsClass 		= '.wd-tabs';
	menuClass 		= '> .wd-tabs-menu';
	linkClass 		= '> .wd-tab-link';
	contentClass 	= '> .wd-tabs-content';
	paneClass 		= '> .wd-tab-pane';

	constructor( el, options ) {
		super( el, options );

		this.el 		= jQuery( this.el );
		this.menu 		= this.el.find( this.menuClass );
		this.links 		= this.menu.find( this.linkClass );
		this.content 	= this.el.find( this.contentClass );
		this.panes 		= this.content.find( this.paneClass );

		this.menu.on( 'click', '.wd-tab-link', ::this.onClick );

		this.links.eq( 0 ).addClass( 'wd-tab-link--active' );
		this.panes.eq( 0 ).addClass( 'wd-tab-pane--active' );
	}

	onClick = ( e ) => {
		const index = jQuery( e.currentTarget ).index();
		this.setTab( index ); 
	}

	setTab( index ) {
		this.links.removeClass( 'wd-tab-link--active' ).eq( index ).addClass( 'wd-tab-link--active' );
		this.panes.removeClass( 'wd-tab-pane--active' ).eq( index ).addClass( 'wd-tab-pane--active' );
	}

	setChildren() {
		this.el 		= jQuery( this.el );
		this.menu 		= this.el.find( this.menuClass );
		this.links 		= this.menu.find( this.linkClass );
		this.content 	= this.el.find( this.contentClass );
		this.panes 		= this.content.find( this.paneClass );
		this.setTab( 0 );

	}

}