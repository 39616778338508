export default class Widgets {

	el = null;
	options = {};

	constructor( el, options ) {
		this.el = el;
		this.options = options; 
	}

}