import Widgets from './Widgets';

export default class WPD_Component_Map extends Widgets {

	map 	= null;
	marker 	= null;

	constructor( el, options ) {
		super( el, options );

		if ( typeof google === typeof undefined ) {
            return;
        }

        this.options = options;
		        
        google.maps.event.addDomListener(window, 'load', ::this.onLoadMap);
        jQuery(window).resize(() => google.maps.event.trigger(this.map, "resize"));
	}

    setOptions( options ) {
        this.map = new google.maps.Map(this.el, options );
        this.marker = new google.maps.Marker({
            position: options.center,
            map: this.map
        });
        this.marker && this.marker.setPosition( options.center );
        this.options = options;
    }

    onLoadMap() {
        this.map = new google.maps.Map(this.el, this.options );
        this.marker = new google.maps.Marker({
            position: this.options.center,
            map: this.map
        });
    }

    setMarkerPosition( center ) {
        this.marker && this.marker.setPosition( center );
    }

}