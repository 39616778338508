import Widgets from './Widgets';

export default class WPD_Component_Counter extends Widgets {

	constructor( el, options ) {
		super( el, options );

		this.el = jQuery( this.el );

		this.render( this.options );

	}

	render( options ) {
		this.el.numerator( options );
	}

}